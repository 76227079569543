/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  background-color: #f9fafb;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: '#f9faf'; */
  background-color: #f9fafb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient {
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}

.scrollbar
{
	background: #f5f5f5;
	overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.mac-scroll::-webkit-scrollbar-track
{
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	border-radius: 10px;
	/* background-color: #F5F5F5; */
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: transparent;
  
}

.mac-scroll::-webkit-scrollbar
{
	width: 6px;  
	/* background-color: #F5F5F5; */
  background-color: transparent;
}

.mac-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	/* background-color: #555; */
  background-color: transparent;
}

