.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shake-x {
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}



.container-audio-bars {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 60px;
  margin: 0 10px;
  /* border: 3px solid #fff; */
}
.bar {
  height: 1px;
  width: 12px;
  background: #fff;
  margin: 2px;
  animation: up  0.6s linear infinite;
}
@keyframes up {
  0% {
      height: 1px;
  }
  50% {
      height: 50px;
  }
  100% {
      height: 1px;
  }
}
.bar:nth-child(2) {
  animation-delay: 0.36s;
}
.bar:nth-child(3) {
  animation-delay: 0.12s;
}
.bar:nth-child(4) {
  animation-delay: 0.24s;
}
.bar:nth-child(5) {
  animation-delay: 0.48s;
}